


<template lang="pug">
    vx-card.mb-base(title="รายรับจากค่าเช่ารายเดือน")
      vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2') ปี :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='4')
            multiselect(
                v-model="yearSelected",
                :options="years",
              )
              span(slot="noResult").
                ไม่พบข้อมูล

      .mt-5
        chartjs-component-bar-chart(:key="keyid" :height="150" :data="thedata" :options="options")

</template>

<script>
import Multiselect from "vue-multiselect";
import { Bar } from 'vue-chartjs'
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue"
export default {
    extends: Bar,
    props: {
        info: {
            type: Object,
            default: null,
        },
    },
    methods: {
      changed(){
        this.data.dataset = [{
                    label: "Population (MMM)",
                    // backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                    // backgroundColor: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
                     backgroundColor: "rgba(60,186,159,0.5)",
                    borderColor: "rgba(60,186,159,1)",
                    data: this.$store.getters["monthlyChart/rentalPrice"]
                }];
       this.renderChart(this.data, this.options);
      }
    },
    computed: {
      years: {
        get: function(){


              return this.$store.getters["monthlyChart/yearlist"];
        },
        set: function(){

        }
      },
      rentalInfo: {
            get: function() {

              return this.$store.getters["monthlyChart/rentalPrice"];

            },
            set: function() {
          }
        },
    },
    async created() {
      var dt = new Date();
      var yyyy = dt.getFullYear();
      console.log('yyyy >>> ',yyyy);

      await this.$store.dispatch("monthlyChart/getAllData",{int1: yyyy});

      let yearList = this.$store.getters["monthlyChart/yearlist"];

      if(yearList === null || yearList === undefined || yearList.length <= 0)
      {
        this.yearSelected = 2021;
      }else{
        this.yearSelected = yearList[0];
      }

      console.log('yearList >>> ',yearList);

    },
    data() {
        return {
          multiselectKey:0,
          yearSelected: 2020,
          keyid: 0,
          waiting: false,
            rental: null,
            thedata: {
                labels: this.$store.getters["monthlyChart/monthName"],
                datasets: [{
                   label: "ยอดเช่า (บาท)",
                     backgroundColor: "rgba(60,186,159,0.5)",
                    borderColor: "rgba(60,186,159,1)",
                    data:  this.rentalInfo
                }]
            },
            options: {
                legend: { display: false },
                title: {
                    display: true,
                    text: ''
                }
            }
        }
    },


    watch: {
      rentalInfo: {
          handler(value){

            this.thedata= {
                labels: this.$store.getters["monthlyChart/monthName"],
                datasets: [{
                    label: "ยอดเช่า (บาท)",
                     backgroundColor: "rgba(60,186,159,0.5)",
                    borderColor: "rgba(60,186,159,1)",
                    data:  value
                }]
            };
            this.keyid += 1;


        },
        deep: true
      },
      yearSelected: async function(){
         await this.$store.dispatch("monthlyChart/getAllData",{int1: this.yearSelected});
      },
      years: {
        handler(value){
          console.log('yearlisth watch >>> ',value);
            this.multiselectKey+= 1;


        },
        deep:true
      }

    },
    async mounted() {
      // var newbar  = Object.assign({}, this.$store.getters["monthlyChart/rentalPrice"]);
      // this.data.dataset = [{
      //               label: "Population (MMM)",
      //                backgroundColor: "rgba(60,186,159,0.5)",
      //               borderColor: "rgba(60,186,159,1)",
      //               data:this.rentalInfo
      //           }];
      // // this.renderChart(this.data, this.options);
      // console.log('this.rentalInfo >>> ',this.rentalInfo);

    },
    components: {
        ChartjsComponentBarChart,
        Multiselect
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div>


        <!-- ROW 2 -->
        <div class="vx-row">

            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                  :key = "key1"
                  hideChart
                  class="mb-base"
                  icon="ShoppingCartIcon"
                  icon-right
                  :statistic="theRentalPrice()"
                  statisticTitle="ค่าเช่าเดือนนี้" />
            </div>

            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                  hideChart
                  :key = "key2"
                  class="mb-base"
                  icon="UserIcon"
                  icon-right
                   :statistic="theOrderNum()"
                  statisticTitle="จำนวนออเดอร์ในเดือนนี้"
                  color="success" />
            </div>

            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3">
                <statistics-card-line
                  :key = "key3"
                  hideChart
                  class="mb-base"
                  icon="ActivityIcon"
                  icon-right
                  :statistic="theNotReturn()"
                  statisticTitle="เงินประกันที่ยังไม่ได้คืน"
                  color="danger" />
            </div>

        </div>


    </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import shapeFormat from "@/mixins/shapeFormat.js";

export default{
    mixins: [shapeFormat],
    components: {
        StatisticsCardLine
    },
    computed: {
      allRental : {
         get: function(){
           return  this.$store.getters["monthlyChart/allRental"];
         },
         set: function(){

         }
      },
      allOrderNumber : {
         get: function(){
           return  this.$store.getters["monthlyChart/allOrderNumber"];
         },
         set: function(){

         }
      },

      allNotReturnedDeposit : {
         get: function(){
           return  this.$store.getters["monthlyChart/allNotReturnedDeposit"];
         },
         set: function(){

         }
      },
    },
    watch:{
      allRental : function(){
        this.key1 += 1;
      },
      allOrderNumber : function(){
        this.key2 += 1;
      },
      allNotReturnedDeposit: function(){
        this.key3 += 1;
      }
    },
    methods: {
      theRentalPrice(){
        return this.formatPrice(this.allRental) + " B"
      },
      theOrderNum(){
         return this.formatPrice(this.allOrderNumber) + " ครั้ง"
      },
      theNotReturn(){
        return this.formatPrice(this.allNotReturnedDeposit) + " B"
      }
    },
    data() {
        return {
            key1: 0,
            key2: 0,
            key3:0,
            // Area charts
            subscribersGained: {},
            revenueGenerated: {},
            quarterlySales: {},
            ordersRecevied: {},

            // Line Charts
            siteTraffic: {},
            activeUsers: {},
            newsletter: {},
        }
    },
    created() {
      // Subscribers gained
      this.$http.get("/api/card/card-statistics/subscribers")
        .then((response) => { this.subscribersGained = response.data })
        .catch((error) => { console.log(error) })

      // Revenue Generated
      this.$http.get("/api/card/card-statistics/revenue")
        .then((response) => { this.revenueGenerated = response.data })
        .catch((error) => { console.log(error) })

      // Sales
      this.$http.get("/api/card/card-statistics/sales")
        .then((response) => { this.quarterlySales = response.data })
        .catch((error) => { console.log(error) })

      // Orders
      this.$http.get("/api/card/card-statistics/orders")
        .then((response) => { this.ordersRecevied = response.data })
        .catch((error) => { console.log(error) })

      // Site Traffic gained
      this.$http.get("/api/card/card-statistics/site-traffic")
        .then((response) => { this.siteTraffic = response.data })
        .catch((error) => { console.log(error) })

      // Active Users
      this.$http.get("/api/card/card-statistics/active-users")
        .then((response) => { this.activeUsers = response.data })
        .catch((error) => { console.log(error) })

      // Site Traffic gained
      this.$http.get("/api/card/card-statistics/newsletter")
        .then((response) => { this.newsletter = response.data })
        .catch((error) => { console.log(error) })
    }
}
</script>



<template lang="pug">
    vx-card.mb-base(title="ประเภทสินค้าขายดีที่สุด 5 อันดับแรก")
      vs-row.pb-6(vs-w='12')
        vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2') ปี :
        vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='4')
          multiselect(
              v-model="yearSelected",
              :options="years",
            )
            span(slot="noResult").
              ไม่พบข้อมูล
        vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='1', vs-sm='1', vs-xs='2') เดือน :
        vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='4')
          multiselect(
              v-model="monthSelected",
              :options="months",
            )
            span(slot="noResult").
              ไม่พบข้อมูล
      .mt-5
        chartjs-component-bar-chart(:key="keyid" :height="150" :data="thedata" :options="options")

</template>

<script>
import { Bar } from 'vue-chartjs'
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue"
import Multiselect from "vue-multiselect";
export default {
    extends: Bar,
    props: {
        info: {
            type: Object,
            default: null,
        },
    },
    methods: {

    },
    computed: {
      years: {
        get: function(){
              return this.$store.getters["monthlyChart/yearlistCategory"];
        },
        set: function(){

        }
      },
      rentalInfo: {
            get: function() {
              return this.$store.getters["monthlyChart/selling"];
            },
            set: function() {
          }
        },
      categoryName: {
            get: function() {
              return this.$store.getters["monthlyChart/category"];
            },
            set: function() {
          }
        },
    },
    async created() {
      // await this.$store.dispatch("monthlyChart/getSellingData",{dt1: new Date("2020-10-01")});
      var dt = new Date();
      this.yearSelected = dt.getFullYear();
      this.monthSelected = dt.getMonth() + 1 ;
      await this.$store.dispatch("monthlyChart/getSellingData",{int1: this.yearSelected, int2: this.monthSelected});
    },
    data() {
        return {
          monthSelected:1,
          months:[1,2,3,4,5,6,7,8,9,10,11,12],
          multiselectKey:0,
          yearSelected: 2020,
          keyid: 0,
          waiting: false,
            rental: null,
            thedata: {
                labels: this.categoryName,
                datasets: [{
                    label: "ยอดเช่า (บาท)",
                    backgroundColor: 'rgba(54, 162, 235, 0.4)',
                    borderColor:  'rgba(54, 162, 235, 1)',
                    data:  this.rentalInfo
                }]
            },
            options: {
                legend: { display: false },
                title: {
                    display: true,
                    text: ''
                }
            }
        }
    },


    watch: {
      rentalInfo: {
          handler(value){
            this.waiting = false;
            this.waiting = true;
            this.thedata= {
                labels: this.categoryName,
                datasets: [{
                    label: "ยอดเช่า (บาท)",
                    backgroundColor: 'rgba(54, 162, 235, 0.4)',
                    borderColor:  'rgba(54, 162, 235, 1)',
                    data:  value
                }]
            };
            this.keyid += 1;


        },
        deep: true
      },
      yearSelected: async function(){
         await this.$store.dispatch("monthlyChart/getSellingData",{int1: this.yearSelected, int2: this.monthSelected});
      },
      monthSelected: async function(){
         await this.$store.dispatch("monthlyChart/getSellingData",{int1: this.yearSelected, int2: this.monthSelected});
      },

    },
    async mounted() {
      // var newbar  = Object.assign({}, this.$store.getters["monthlyChart/rentalPrice"]);
      // this.data.dataset = [{
      //               label: "Population (MMM)",
      //                backgroundColor: "rgba(60,186,159,0.5)",
      //               borderColor: "rgba(60,186,159,1)",
      //               data:this.rentalInfo
      //           }];
      // // this.renderChart(this.data, this.options);
      // console.log('this.rentalInfo >>> ',this.rentalInfo);

    },
    components: {
        ChartjsComponentBarChart,
        Multiselect
    }
}
</script>

<!-- =========================================================================================
    File Name: Charts.vue
    Description: Chartjs (third-party) - Imports page portions
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <div id="extra-component-chartjs-demo">

        <statistic-card> </statistic-card>
        <div class="vx-row">
            <div class="vx-col w-full">
                <selling-stat-chart></selling-stat-chart>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <monthly-chart  ></monthly-chart>
            </div>
        </div>

    </div>
</template>

<script>
import StatisticCard from "./StatisticCard.vue"
import SellingStatChart from "./SellingStatChart.vue"
import MonthlyChart from "./MonthlyChart.vue"


export default {
  data() {
    return {
      thisBranch : null,
      res : null
    }
  },
    async created() {
          this.currentUser = JSON.parse(localStorage.getItem("username"));

          this.thisBranch = this.$store.state.auth.currentBranch;



    },
    components: {
        MonthlyChart,
        StatisticCard,
        SellingStatChart

    }
}
</script>
